body, html{
    margin: 0;
}

:root{
    --primary: #ffffff;
}

*{
    font-family: 'Montserrat', sans-serif !important;
}

.css-kuifbp-MuiButtonBase-root-MuiButton-root:hover{
    background-color: var(--primary) !important;
}

.PrivateTabIndicator-colorSecondary-3{
    background-color: var(--primary) !important;
}

#btn_new_mbtable {
    background-color: var(--primary) !important;
    color: white;
    box-shadow: unset;
}

#btn_edit_mbtable{
    background-color: var(--primary) !important;
    color: white;
}

.PrivateTabIndicator-colorSecondary-7{
    background-color: var(--primary) !important;
}

.css-yx0nvq-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected{
    background-color: var(--primary) !important;
    color: white;
}

.css-wjh20t-MuiPagination-ul {
    background: white;
    padding: 10 !important;
    border: solid 1px #e6e6e6;
    border-radius: 5px;
    padding: 10 !important;
}

#btn_change_page{
    background-color: var(--primary) !important;
    color: white;
}

.css-10w330c-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected{
    background-color: var(--primary) !important;
    color: white;
}

#app_bar{
    background: var(--primary) !important;
    color: white;
    /*box-shadow: 0px 3px 8px 1px #e0e0e0;*/
}

.MuiSwitch-root{
    color: var(--primary) !important;
}

.MuiSwitch-colorPrimary.Mui-checked{
    color: var(--primary) !important;
}

.MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track{
    background-color: var(--primary) !important;
}

.MuiAccordionDetails-root{
    display: block !important;
}

.css-wjh20t-MuiPagination-ul{
    justify-content: space-between;
}

.save-button{
    background-color: var(--primary) !important;
    color: white !important;
    width: 250px;
    height: 50px;
    position: fixed !important;
    bottom: 30px;
    right: 30px;
    z-index: 2 !important;
}

.MuiDialogTitle-root+.css-11msd0-MuiDialogContent-root{
    min-height: 40px;
    max-height: 50px;
}

.MuiDialogTitle-root + .css-1u1dzsr {
    min-height: 40px !important;
    padding-top: 0px;
    max-height: 50px !important;
}

.Mui-disabled{
    cursor: pointer !important;
}

.css-1idz92c-MuiCircularProgress-svg{
    color: var(--primary) !important;
}

.css-ulb0ho.Mui-selected{
    background-color: var(--primary) !important;
    color: white !important;
}

.css-1g86id8-MuiTreeItem-content.Mui-selected:hover{
    background-color: transparent !important;
}

.css-1g86id8-MuiTreeItem-content.Mui-selected{
    background-color: transparent !important;
}

.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked, .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate{
    color: var(--primary) !important;
}

.css-1f2kvjf-MuiFormControlLabel-root{
    justify-content: start;
}

.css-8l6tpu{
    justify-content: start;
}

.css-yuu8jj.Mui-selected{
    background-color: transparent !important;
}

.css-zun73v{
    color: var(--primary) !important;
}

.css-yuu8jj .MuiTreeItem-iconContainer svg{
    color: black !important
}

.general_card{
    border: 0.1px solid #e7e7e7;
    padding: 20px;
    width: 100%;
}

.css-1aquho2-MuiTabs-indicator{
    background-color: var(--primary) !important;
}

.css-ttwr4n{
    background-color: var(--primary) !important;
}

.MuiOutlinedInput-root{
    /*border-radius: 0px !important;*/
}

.css-1bn53lx{
    /*border-radius: 0px !important;*/
}

.css-13o7eu2{
    color: var(--primary) !important;
}

.MuiCircularProgress-svg{
    color: var(--primary) !important;
}

.css-19qh8xo-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled{
    -webkit-text-fill-color: black !important;
}

.css-1nd7vgx-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled{
    -webkit-text-fill-color: black !important;
}

.css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled{
    -webkit-text-fill-color: black !important;
}

.css-b52kj1.Mui-disabled{
    -webkit-text-fill-color: black !important;
}


/* stile di Carlo */

.selected-voice-menu > span{
    font-weight: 500 !important;
    background-color: var(--primary) !important;
    color: white;
    text-align: center;
    border-radius: 1000px;
}

div#drawer_sidebar ul > li {
    padding: 10px;
}
.chart-container {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 7px 11px 1px #f3f3f3;
    margin-top: 20px;
}
.counter-container.gradient{
    background-image: linear-gradient(rgba(65, 65, 65, 0.692), var(--primary));
    color: white;
}
.counter-container {
    display: flex;
    flex-direction: row;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 7px 11px 1px #f3f3f3;
    padding:30px;
    margin-top: 30px;
    height: 100% !important;
    width: 100% !important;
}
.counters-row {
    display: flex;
    width: 100%;
    gap: 30px !important;
    justify-content: space-between !important;
}
.counter-title{
    font-size: 18px !important;
    font-weight: 600;
}
.dashboard-container {
    background: #f9f9f9;
    padding: 25px;
    margin: -25px;
}
#drawer_sidebar > div {
    border-right: unset;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
}
td {
    border: unset !important;
    border-top: unset !important;
    padding: 12px !important;
}
thead * {
    font-size: 18px !important;
    border: solid 1px #f4f4f414;
    border-width: 0px 1px;
}
main {
    background: white;
    min-height: 100vh;
}
table {
    background: #fff !important;
    border: solid 1px #ececec;
    border-radius: 15px;
    overflow: hidden;
}

.general_card {
    border: unset;
    padding: 30px;
    width: 100%;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 7px 11px 1px #f3f3f3;
}

.general_card.document{
    background-color: transparent !important;
    padding: 0px !important;
    box-shadow: unset !important;
}

.card {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 7px 11px 1px #f3f3f3;
}
div#tab_head {
    padding: 0px !important;
    margin-top: 20px;
}
.MuiTabs-flexContainer.css-k008qs {
    background: #fff;
    overflow: hidden;
}
.css-1iji0d4 {
    min-height: 40px;
}
.css-1dvqfyr {
    padding: 0px 16px;
}
.css-1f2uydt {
    padding: 0px 0px;
}
.css-g0op70 {
    padding: 0px 16px;
}
.css-67l5gl {
    border: unset !important;
}
	.css-67l5gl::before {
    display: none;
}
/* stile di Carlo */

.login-form{
    display: flex;
    flex-direction: column;
    width: 400px !important;
    text-align: center;
    gap: 15px;
    padding: 40px;
    border-radius: 10px;
}

.mallbox-appbar{
    box-shadow: none !important;
}

.parent-new-customer input{
    background-color: white !important;
}

.MuiOutlinedInput-root{
    background-color: white !important;
}

.card-enterprise{
    background-color: white !important;
}

.dense-menu{
    padding-left: 10px !important;
}

.css-67l5gl{
    border: 1px solid rgb(222, 222, 222) !important;
}