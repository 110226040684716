:root{
    --primary: #ffffff;
}

.action-button{
    cursor: pointer;
    font-size: 30px;
}

.search{
    color: orange;
    font-size: 25px;
}

.delete{
    color: red;
    font-size: 25px;
}

.more{
    color: green;
    font-size: 25px;
}

.table-column{
    background-color: var(--primary) !important;
    color: white !important;
}

.MuiSlider-root {
    color: #20b2aa;
}

.MuiSlider-root:hover {
    color: #2e8b57;
}

body > div.MuiModal-root.MuiDialog-root.css-zw3mfo-MuiModal-root-MuiDialog-root > div.MuiDialog-container.MuiDialog-scrollPaper.css-hz1bth-MuiDialog-container > div > div:nth-child(3) > div > div:nth-child(2) > span > div{
    justify-content: start !important;
}
